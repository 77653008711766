import { ReactElement } from 'react';
import { defaultElement } from '@/components/modules/BodyContentModule';
import { BodyContentCommonModuleType } from '@/components/modules/BodyContentCommonModule';
import { BodyContentModulesGameSet, BodyContentModulesGamesCharacterFeatureCollage, BodyContentModulesGamesCollage, BodyContentModulesGamesContentCards, Game, GamesContentCard } from '@/types/pbskids-graph';
import GamesCharacterFeatureCollage from '@/components/modules/GamesCharacterFeatureCollage';
import GamesCollage from '@/components/modules/GamesCollageModule';
import GameSet from '@/components/modules/GameSetModule';
import { ResolvedPageThemes } from '@/utils/theming-system';
import Logger from '@/utils/logger/base';
import GridLowerModule from './GridLowerModule';
import RowLowerModule from './RowLowerModule';
import { filterCards } from '@/utils/cards/filters';

const logger = new Logger({ caller: 'components.modules.BodyContentGameModule' });

export type BodyContentGameModuleType =
  BodyContentCommonModuleType |
  BodyContentModulesGamesCollage |
  BodyContentModulesGamesCharacterFeatureCollage |
  BodyContentModulesGameSet |
  BodyContentModulesGamesContentCards;

// Remove games that don't have requisite content to build a card
const filterGames = (games: Game[], module: BodyContentGameModuleType ) => {
  if (games?.length) {
    return games.filter((game) => {
      const valid = game.mezzanine?.length && game.websiteUrl;
      if (!valid) {
        logger.warn(`Game entry id: ${game.id} ("${game.title}") missing either websiteUrl or mezzanine on ${module.__typename}.`);
      }
      return valid;
    });
  } else {
    logger.warn(`Game entries not found on ${module.__typename}. Returned data: ${JSON.stringify(games, null, 2)}`);
    return [];
  }
};

export default function BodyContentGameModule({ module }: {
  module: BodyContentGameModuleType,
  themeData?: ResolvedPageThemes,
}): ReactElement {
  switch (module.__typename) {
    case 'BodyContentModulesGamesCollage': {
      if (!module.games || !module.games[0]) {
        return defaultElement;
      }

      const games = module.games as Game[];

      const moduleData = {
        featuredItem: module.features?.[0] || undefined,
        lookInside: module.enableLookInside,
        games: filterGames(games, module),
        heading: module.heading as string,
        headingIcon: module.headingIcon,
      };

      return <GamesCollage
        featuredItem={ moduleData.featuredItem }
        listId={ module.id}
        games={ moduleData.games }
        heading={ moduleData.heading }
        headingIcon={moduleData.headingIcon?.[0]?.uiAssets?.[0]?.asset?.[0]?.url || ''}
        theme={ module.theme }
      ></GamesCollage>;
    }

    case 'BodyContentModulesGamesCharacterFeatureCollage': {
      if (!((module?.games?.length || 0) > 0)) {
        return defaultElement;
      }

      const games = filterGames(module.games as Game[], module);

      return <>
        <GamesCharacterFeatureCollage
          characterImage={ module.characterImage }
          games={ games }
          layout={ module.layout || undefined }
          listId={ module.id }
          theme={ module.theme }
        />
      </>;
    }

    case 'BodyContentModulesGameSet': {
      if (!module.collection || !module.collection[0]) {
        return defaultElement;
      }

      const games = module.collection[0].entries as Game[];

      const moduleData = {
        games: filterGames(games, module),
        heading: module.heading as string,
        headingIcon: module.headingIcon,
      };

      return <GameSet
        games={ moduleData.games }
        listId={ module.id }
        heading={ moduleData.heading }
        headingIcon={moduleData.headingIcon?.[0]?.uiAssets?.[0]?.asset?.[0]?.url || ''}
        theme={module.theme?.[0] || module.collection[0].theme}
      ></GameSet>;
    }

    case 'BodyContentModulesGamesContentCards': {
      const cards = module.cards as GamesContentCard[];
      const moduleData = {
        cardStyle: module.cardStyle as string,
        linkItems: cards.filter(filterCards(module.cardStyle as string)),
        listId: module.id,
        title: module.heading as string,
      };
      if ( !moduleData.linkItems.length ) {
        return defaultElement;
      }

      if (module.layout === 'grid') {
        return <GridLowerModule
          title={ moduleData.title }
          linkItems={ moduleData.linkItems }
          cardStyle={ moduleData.cardStyle }
          listId={ moduleData.listId }
        ></GridLowerModule>;
      }

      if (module.layout === 'row') {
        return <RowLowerModule
          title={ moduleData.title }
          linkItems={ moduleData.linkItems }
          cardStyle={ moduleData.cardStyle }
          listId={ moduleData.listId }
        ></RowLowerModule>;
      }

      return defaultElement;
    }

    default:
      return defaultElement;
  }
}
