import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Maybe, VideoContentCard, LinkTargetContent, GamesContentCard, GamesContentCardTarget } from '@/types/pbskids-graph';
import styles from './MediaItem.module.scss';
import Logger from '@/utils/logger/base';

const logger = new Logger({ caller: 'components.modules.MediaList.LinkCard' });

interface Props {
  item: VideoContentCard | GamesContentCard,
  sizes?: string,
  cardStyle?: string,
  displayTitle?: boolean,
}

export const buildCardLink = (target: LinkTargetContent | GamesContentCardTarget) => {
  if (target) {
    const targetType = target.__typename;

    switch (targetType) {
      case 'PbsKidsVideo':
      case 'PbsKidsPodcastEpisode':
        return `/videos/watch/${target.slug}/${target.id}`;

      case 'PbsKidsVideoPlaylist':
      case 'PbsKidsVideosByPropertyCollection':
      case 'PbsKidsPodcastEpisodePlaylist':
        return `/videos/playlist/${target.slug}/${target.id}`;

      case 'PbsKidsVideosWebPage':
        return `/videos/${target.uri}`;

      case 'PbsKidsGamesWebPage':
        return `/games/${target.uri}`;

      case 'Game':
        return `${target.websiteUrl}`;

      default:
        logger.warn(`targetType, '${targetType}', is not currently supported.`);
        return '#';
    }
  }
};

const getCardImage = (card: VideoContentCard | GamesContentCard, cardStyle: string | undefined) => {
  const targetType = card.target?.[0]?.__typename;

  if (card?.cardImage) {
    return card?.cardImage?.[0];
  } else if (targetType === 'PbsKidsVideo') {
    const videoTarget = card?.target?.[0];

    switch (cardStyle) {
      case 'smallSquare':
      case 'largeSquare':
        return videoTarget?.squareImage?.[0];
      case 'poster':
        return videoTarget?.posterImage?.[0];
      case 'mezzanine':
        return videoTarget?.mezzanine?.[0];
      default:
        return;
    }
  } else if (targetType === 'Game') {
    const gameTarget = card?.target?.[0];

    switch (cardStyle) {
      case 'mezzanine':
        return gameTarget?.mezzanine?.[0];
      default:
        return;
    }
  }
};

const LinkCard: React.FC<Props> = ({
  item,
  sizes,
  cardStyle,
  displayTitle,
}) => {
  const [ imageAltText, setImageAltText ] = useState<string>('');
  const target = item?.target?.[0];
  const link = target ? buildCardLink(target) : '#';
  const cardTitle = item?.label || target?.title || '';

  const targetIsVideo = target?.__typename === 'PbsKidsVideo';
  const targetIsGame = target?.__typename === 'Game';

  const getUniqueTitleText = (
    chooseFrom: Array<string | null | undefined>,
    uniqueAgainst: string | null | undefined,
  ) => {
    const uniqueTitle = chooseFrom.find((titleCandidate) => titleCandidate && uniqueAgainst !== titleCandidate) || '';

    if (displayTitle && cardTitle === uniqueTitle) {
      return '';
    } else {
      return uniqueTitle;
    }
  };

  const CardImage = () => {
    const cardImage = getCardImage(item, cardStyle);

    if (targetIsVideo && cardStyle === 'mezzanine' && !cardImage) {
      const videoItemMedia = target?.mediaManagerAsset;
      const mediaManagerImage = videoItemMedia?.images?.find((image) => image?.profile === 'asset-kids-mezzanine1-16x9');

      return ( mediaManagerImage &&
        <div data-use-loading-dots>
          <Image
            src={ mediaManagerImage?.image as string }
            alt={ imageAltText }
            sizes={ sizes || '100vw'}
            width={ 550 }
            height={ 310 }
          />
        </div>
      );
    } else if (targetIsGame && cardStyle === 'mezzanine' && !cardImage) {
      const gameItemMedia = target?.mezzanine?.[0]?.url;

      return ( gameItemMedia &&
        <div data-use-loading-dots>
          <Image
            src={ gameItemMedia }
            alt={ imageAltText }
            sizes={ sizes || '100vw'}
            width={ 550 }
            height={ 310 }
          />
        </div>
      );
    } else {
      return ( cardImage &&
        <div
          className={ styles.mediaContainer}
          data-use-loading-dots
        >
          <Image
            src={ cardImage?.url as string}
            alt={ imageAltText }
            sizes={ sizes || '100vw'}
            width={ cardImage?.width || 300 }
            height={ cardImage?.height || 300 }
          />
        </div>
      );
    }
  };

  const CardCaption = () => {
    return (cardTitle && <p className={`${styles.heading} ${styles.linkCardTitle}`}>{cardTitle}</p>
    );
  };

  useEffect(() => {
    const buildImageAltText = (preferredAlt: Maybe<string> = ''): string => {
      const altText = preferredAlt || item?.label || target?.title || '';
      if (displayTitle && cardTitle === altText) {
        return '';
      } else {
        return altText;
      }
    };

    if (targetIsVideo) {
      const videoItemMedia = target?.mediaManagerAsset;
      setImageAltText(buildImageAltText(videoItemMedia?.description_short));
    } else {
      const cardImage = getCardImage(item, cardStyle);
      setImageAltText(buildImageAltText(cardImage?.altText));
    }
  }, [
    cardStyle,
    cardTitle,
    displayTitle,
    item,
    target,
    targetIsVideo,
  ]);

  return (
    <>
      { link && displayTitle && <>
        <article
          data-hover-group='media-card'
          className={`${styles.linkCard} ${styles.hasTitle}`}
        >
          <CardImage/>
          <Link
            href={link || '#'}
            title={getUniqueTitleText([ target?.title, item.label ], imageAltText) || undefined}
            className={styles.mediaLink}
          >
            <div className={`${styles.mediaCardDetailsContainer} mediaCardDetails`}>
              <CardCaption/>
            </div>
          </Link>
        </article>
      </>}

      { link && !displayTitle && <>
        <article
          className={styles.linkCard}
        >
          <Link
            href={link || '#'}
            title={getUniqueTitleText([ target?.title, item.label ], imageAltText) || undefined}
          >
            <CardImage/>
          </Link>
        </article>
      </>}

      { !link && <>
        <article className={styles.linkCard}>
          <CardImage/>;
        </article>
      </>}
    </>
  );
};

export default React.memo(LinkCard);
