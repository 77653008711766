export function setLookInsidePlayTextColor(elements: HTMLElement[]) {
  /* Play button text color should always be white UNLESS the background is white, then it should be purple.
  Since the background is a themed CSS variable, we don't know what its value is until runtime and have to compare then. */

  elements.forEach((element) => {
    const uiHighlightColor = window.getComputedStyle(element).getPropertyValue('--pbsk-theme-uiHighlightColor');

    if (uiHighlightColor === '#ffffff') {
      element.style.setProperty('--pbsk-component-lookinside-play-textColor', 'var(--pbsk-color-purple)');
    } else {
      element.style.setProperty('--pbsk-component-lookinside-play-textColor', '#fff');
    }
  });
}
