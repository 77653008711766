import React from 'react';

import styles from './GridLowerModule.module.scss';
import MediaList from '../MediaList';
import { PbsKidsVideo, VideoContentCard, GamesContentCard } from '@/types/pbskids-graph';
import { MediaContext } from '@/types/mediaContext';

interface Props {
  cardStyle?: string,
  context?: MediaContext,
  items?: PbsKidsVideo[],
  linkItems?: VideoContentCard[] | GamesContentCard[],
  listId?: string,
  title?: string,
}

const GridLowerModule: React.FC<Props> = ({
  cardStyle,
  context,
  items,
  linkItems,
  listId,
  title,
}) => {
  return (
    <section>
      { title ?
        <h2 className={ styles.title }>
          { title }
        </h2> :
        <div className={ `${styles.title} ${styles.emptyTitle}` }>
          &nbsp;
        </div>
      }

      <MediaList
        items={ items }
        linkItems={ linkItems }
        listId={ listId }
        context={ context }
        cardStyle= { cardStyle }
        className= {`${styles.grid} ${styles[cardStyle as keyof typeof styles] || ''}`}
      ></MediaList>
    </section>
  );
};

export default GridLowerModule;
