import { ReactElement } from 'react';
import { MastheadContentModulesFreeformGraphicAndText, MastheadContentModulesVideo, MastheadContentModulesVideoPlaylist, MastheadContentModulesVideoContentCards, PbsKidsVideo, VideoContentCard, MastheadContentModulesLookInsideGamePreview } from '@/types/pbskids-graph';
import { filterVideos, filterCards } from '@/utils/cards/filters';
import MastheadLookInsideGamePreview from '@/components/modules/MastheadLookInsideGamePreview';
import FreeFormModule from '@/components/modules/FreeFormModule';
import SingleVideoModule from '@/components/modules/SingleVideoModule';
import CarouselModule from '@/components/modules/CarouselModule';

const defaultElement = <div style={{ display: 'none' }}></div>;

export type MastheadContentModuleType =
  MastheadContentModulesVideo |
  MastheadContentModulesFreeformGraphicAndText |
  MastheadContentModulesLookInsideGamePreview |
  MastheadContentModulesVideoPlaylist |
  MastheadContentModulesVideoContentCards;

export default function MastheadContentModule({ module }: {
  module: MastheadContentModuleType,
}): ReactElement {
  switch (module.__typename) {
    case 'MastheadContentModulesVideo':
      if (!module.video || !module.video[0]) {
        return defaultElement;
      }
      return <>
        <SingleVideoModule
          item={module.video[0]}
          inMasthead={true}
          listId={module.id}
        />
      </>;

    case 'MastheadContentModulesFreeformGraphicAndText':
      return <>
        <FreeFormModule
          module={module}
          inMasthead = {true}
        />
      </>;

    case 'MastheadContentModulesLookInsideGamePreview':
      if (!module.game?.length) {
        return defaultElement;
      }

      return <MastheadLookInsideGamePreview
        game={module.game?.[0]}
        listId={module.id}
      />;

    case 'MastheadContentModulesVideoPlaylist': {
      if (!module.collection || !module.collection[0]) {
        return defaultElement;
      }

      // If playlist belongs to a only a single property, then don't display the video's show title.
      const displayShowTitle = !(module.collection[0].properties?.length === 1);
      const videos = module.collection[0].entries as PbsKidsVideo[];
      const moduleData = {
        cardStyle: module.cardStyle as string,
        context: {
          id: module.collection[0].id,
          slug: module.collection[0].slug,
          title: module.collection[0].title,
          displayShowTitle: displayShowTitle,
        },
        items: videos.filter(filterVideos(module.cardStyle as string)),
        title: module.heading as string,
      };

      if (moduleData.items.length) {
        return <CarouselModule
          items={ moduleData.items }
          cardStyle='mezzanine'
          context={ moduleData.context }
          listId={ module.id }
          inMasthead={true}
        ></CarouselModule>;
      }

      return defaultElement;
    }

    case 'MastheadContentModulesVideoContentCards': {
      if ( !module.cards ) {
        return defaultElement;
      }

      const cards = module.cards as VideoContentCard[];
      const moduleData = {
        cardStyle: module.cardStyle as string,
        linkItems: cards.filter(filterCards(module.cardStyle as string)),
        listId: module.id,
        title: module.heading as string,
      };

      if (moduleData.linkItems.length) {
        return <CarouselModule
          linkItems={ moduleData.linkItems }
          cardStyle='mezzanine'
          listId={ moduleData.listId }
          inMasthead={true}
        ></CarouselModule>;
      }

      return defaultElement;
    }

    default:
      return defaultElement;
  }
}
