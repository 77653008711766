import { PbsKidsVideo, VideoContentCard, GamesContentCard } from '@/types/pbskids-graph';
import React, { ReactElement } from 'react';
import styles from './MediaItem.module.scss';
import sliderStyles from '@/components/modules/RowLowerModule/RowLowerModule.module.scss';
import VideoCard from './VideoCard';
import LinkCard from './LinkCard';
import { MediaContext } from '@/types/mediaContext';
interface Props {
  cardStyle?: string,
  className?: string,
  context?: MediaContext,
  inMasthead?: boolean,
  isSlider?: boolean,
  isCarousel?: boolean,
  items?: PbsKidsVideo[],
  linkItems?: VideoContentCard[] | GamesContentCard[],
  listId?: string,
  innerRef?: React.MutableRefObject<HTMLLIElement[] | null>,
}

const imageSizes = (cardStyle: string | undefined, isCarousel: boolean) => {
  const carouselImageSizes = isCarousel ?
    '(min-width: 1024px) 600px, (min-width: 568px) 60vw, 95vw' :
    '(min-width: 1024px) 305px, (min-width: 568px) 47vw, 95vw';
  switch (cardStyle) {
    case 'smallSquare':
      return '(max-width: 569px) 80px, 150px';
    case 'largeSquare':
      return '(max-width: 569px) 150px, 125px';
    case 'poster':
      return '(max-width: 569px) 200px, 300px';
    case 'mezzanine':
    default:
      return carouselImageSizes;
  }
};

// The list gets special rules if any Video Content Card links to a video
const mixedContentWithTitles = (items: VideoContentCard[] | GamesContentCard[], cardStyle?: string) => {
  return !!items.some((item) => item?.target?.[0]?.__typename === 'PbsKidsVideo') && cardStyle === 'mezzanine';
};

export function populateVideoItem(
  item: PbsKidsVideo,
  isCarousel: boolean,
  context?: MediaContext,
  cardStyle?: string,
  inMasthead?: boolean,
): ReactElement {
  return <VideoCard
    item={ item }
    context={ context }
    inMasthead={ inMasthead }
    sizes={ imageSizes(cardStyle, isCarousel)}
    cardStyle={ cardStyle }
  ></VideoCard>;
}

export function populateLinkItem(
  item: VideoContentCard | GamesContentCard,
  isCarousel: boolean,
  mixedContent: boolean,
  cardStyle?: string,
): ReactElement {
  if (item.target?.[0]?.__typename === 'PbsKidsVideo' && mixedContent) {
    return <VideoCard
      sizes={ imageSizes(cardStyle, isCarousel)}
      item={ item.target[0] as PbsKidsVideo}
      cardStyle={ cardStyle }
    />;
  } else {
    return <LinkCard
      sizes={imageSizes(cardStyle, isCarousel)}
      item={ item }
      cardStyle={ cardStyle }
      displayTitle={ mixedContent }
    />;
  }
}

const MediaList: React.FC<Props> = ({
  cardStyle,
  className,
  context,
  inMasthead,
  isCarousel = false,
  isSlider = false,
  items = [],
  linkItems = [],
  listId,
}) => {
  const sliderClass = isSlider ? `${sliderStyles.emblaContainer}` : '';
  const slideClass = isSlider ? `${sliderStyles.emblaSlide}` : '';
  const mixedContent = mixedContentWithTitles(linkItems, cardStyle);

  const MediaCardList = () => {
    if (items.length && cardStyle) {
      return <>
        {items.map((item: PbsKidsVideo, index: number ) => (
          <li
            className={`${styles.mediaItem} ${slideClass}`}
            data-index={ index }
            key={ index }
          >
            { populateVideoItem(item, isCarousel, context, cardStyle, inMasthead) }
          </li>
        ))}
      </>;
    } else if (linkItems.length && cardStyle) {
      return <>
        {linkItems.map((item: VideoContentCard | GamesContentCard, index: number ) => (
          <li
            className={`${styles.mediaItem} ${slideClass}`}
            data-index={ index }
            key={ index }
          >
            { populateLinkItem(item, isCarousel, mixedContent, cardStyle) }
          </li>
        ))}
      </>;
    } else return <></>;
  };

  return (
    <ul
      data-ga-view-list-module={listId}
      className={`${className || ''} ${styles[cardStyle as keyof typeof styles] || ''} ${sliderClass}`}>
      <MediaCardList/>
    </ul>
  );
};

export default MediaList;
