import Head from 'next/head';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { getModuleThemeContextId, getModuleThemeCss } from '@/utils/theming-system/modules';
import { Maybe, PbsKidsTheme } from '@/types/pbskids-graph';
import { ModuleBackgroundType } from '@/utils/theming-system/modules';
import { setLookInsidePlayTextColor } from '@/components/modules/LookInsideCard/text-color';
import GlobalStateContext from '@/components/global/GlobalState';

import styles from './ThemedModule.module.scss';

function BackgroundLayers({ backgroundMode }: { backgroundMode: ModuleBackgroundType }) {
  return <>
    <div className={styles.bgLayers}>
      <div className={styles.solidColor} />

      {backgroundMode === 'pattern' && <div className={styles.pattern} />}
      {backgroundMode === 'image' && <div className={styles.image} />}
    </div>
  </>;
}

type DebuggerProps = {
  css: string,
  theme: PbsKidsTheme
  themeContextId: string
  backgroundMode: ModuleBackgroundType
};

const DebuggerTool = ({ css, theme, backgroundMode, themeContextId }: DebuggerProps) => <>
  <div className={styles.themedModuleDebugger}>
    <details>
      <summary>View Rendered Module Theme CSS</summary>
      <pre dangerouslySetInnerHTML={{ __html: css }}></pre>
    </details>
    <details>
      <summary>View Source Theme Data</summary>
      <pre dangerouslySetInnerHTML={
        {
          __html: JSON.stringify(
            {
              themeContextId,
              backgroundMode,
              theme,
            },
            null, 1,
          ),
        }
      } />
    </details>
  </div>
</>;

type Props = {
  theme?: Maybe<PbsKidsTheme> | Maybe<Array<Maybe<PbsKidsTheme>>>
  backgroundMode: ModuleBackgroundType
  contentClassName?: string
  wrapperClassName?: string
  children?: ReactNode
};

export default function ThemedModule({
  theme,
  backgroundMode,
  contentClassName,
  wrapperClassName,
  children,
}: Props) {
  const wrapperRef = useRef<HTMLDivElement|null>(null);
  const { showThemeDevUI } = useContext(GlobalStateContext);
  const [ showDebugTag, setShowDebugTag ] = useState<boolean>(false);

  theme = Array.isArray(theme) ? theme[0] : theme;

  const wrapperClasses = styles.moduleWrapper + (wrapperClassName ? ` ${wrapperClassName}` : '');
  const contentClasses = styles.innerContent + (contentClassName ? ` ${contentClassName}` : '');

  useEffect(() => {
    if (showDebugTag !== showThemeDevUI) {
      setShowDebugTag(showThemeDevUI);
    }

    if (wrapperRef.current) {
      setLookInsidePlayTextColor(
        [ wrapperRef.current as HTMLElement ],
      );
    }
  }, [ showDebugTag, showThemeDevUI ]);

  // If no proper theme is provided, render the children without any theming
  if (!theme || !theme.slug) {
    return <>
      <div className={wrapperClasses}>
        <div className={contentClasses}>
          {children}
        </div>
      </div>
    </>;
  }

  const css = getModuleThemeCss(theme, backgroundMode);
  const themeContextId = getModuleThemeContextId(theme, backgroundMode);

  return <>
    <div
      data-theme-context='module'
      data-theme-module-contextid={themeContextId}
      className={wrapperClasses}
      ref={wrapperRef}
    >
      <Head>
        <style
          data-pbsk-theme-styles-source='module'
          data-pbsk-theme-module-contextid={themeContextId}
          dangerouslySetInnerHTML={{ __html: css }}
        />
      </Head>

      <BackgroundLayers backgroundMode={backgroundMode} />

      <div className={contentClasses}>
        {children}
      </div>

      {showDebugTag && <DebuggerTool
        css={css}
        backgroundMode={backgroundMode}
        themeContextId={themeContextId}
        theme={theme}
      />}
    </div>
  </>;
}
