import { useContext, useEffect, useState } from 'react';
import PageSectionStack from '@/components/base/PageSectionStack';
import pageSectionStackStyles from '@/components/base/PageSectionStack/PageSectionStack.module.scss';
import Head from '@/components/global/Head';
import LazyLoader from '@/components/base/LazyLoader';
import ThemeStyles from '@/components/global/ThemeStyles';
import BodyContentModule, { BodyContentModuleType } from '@/components/modules/BodyContentModule';
import MastheadContentModule from '@/components/modules/MastheadContentModule';
import MastheadContentWrapper from '@/components/modules/MastheadContentModule/MastheadContentWrapper';
import SponsorsGrid from '@/components/modules/SponsorsGrid';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import { hasValidMastheadContent } from '@/utils/cards/filters';
import { setLookInsidePlayTextColor } from '@/components/modules/LookInsideCard/text-color';
import { TrackableModulesType, trackModuleViewOnLazyLoad } from '@/managers/Analytics/pageModuleEvents';
import { useGACTAClickTracker } from '@/managers/Analytics/useGACTAClickTracker';
import { useGAViewListTracker } from '@/managers/Analytics/useGAViewListTracker';
import { useSetScrollbarWidthVar } from '@/managers/Layout/useSetScrollbarWidthVar';
import {
  CmsSite,
  BodyContentModulesVideoContentCards,
  BodyContentModulesVideoPlaylist,
  MastheadContentModule as MastheadContentModuleType,
  PbsKidsPage,
  PbsKidsTheme,
  PbsKidsVideosWebPage,
  Sponsor,
  PbsKidsProperty,
  PbsKidsGamesWebPage,
  MastheadContentModulesVideoPlaylist,
  MastheadContentModulesVideoContentCards,
} from '@/types/pbskids-graph';
import { queryGraphServer } from '@/utils/graphql';
import { isPrivatePage } from '@/utils/page';
import { getResolvedPageThemeData, getThemeFromAncestors, ResolvedPageThemes, setStationThemeOverride } from '@/utils/theming-system';
import { DocumentNode } from 'graphql';

export type PageProps = {
  hasMastheadContent: boolean,
  isHomePage: boolean,
  pageData: PbsKidsVideosWebPage,
  pageDescription: string,
  pageTitle: string,
  pageProperty?: PbsKidsProperty,
  pageModuleCategory: 'game' | 'video',
  resolvedPageThemes: ResolvedPageThemes,
};

export async function getPageProps(
  context: { params: { 'uriParts': string[]; }; },
  pageQuery: DocumentNode,
  site: CmsSite,
  description: string,
  title: {
    verb: string,
    noun: string,
  },
  pageModuleCategory: string,
) {
  // The homepage URLs, /videos and /games, won't yield an array of uri parts.
  // Query graph explicity for `uri = '__home__'` instead of `uri = ''`.
  const uri = context.params['uriParts']?.join('/') || '__home__';

  // In dev mode I keep seeing relative requests to js/source map files that obviously aren't valid pages in the CMS.
  if (uri.match(/\.js(\.map)?$/)) {
    return {
      notFound: true,
    };
  }

  // Some pages should only be available on debug/staging/preprod environments.
  if (isPrivatePage(uri)) {
    return {
      notFound: true,
    };
  }

  // Return 404 if no graph-page-query is defined.
  if (!pageQuery) {
    return {
      notFound: true,
    };
  }

  // Fetch page data from graph.
  const result = await queryGraphServer(pageQuery, { uri });

  // Page not found.
  if (!result?.pbsKidsPageByUri) {
    return {
      notFound: true,
    };
  }

  const isHomePage = (uri === '__home__');
  const pageData: PbsKidsVideosWebPage | PbsKidsGamesWebPage = result.pbsKidsPageByUri;
  const hasMastheadContent = !!(
    pageData.mastheadContentModules &&
    pageData.mastheadContentModules.length > 0 &&
    hasValidMastheadContent(pageData.mastheadContentModules[0] as MastheadContentModuleType)
  );

  const pageProperty = pageData?.property?.[0] || null;
  const pageTitle = (() => {
    if (!isHomePage) {
      if (pageProperty?.title) {
        return `${title.verb} ${pageProperty.title} ${title.noun}`;
      } else if (pageData.title) {
        return `${pageData.title} | ${title.noun}`;
      }
    }

    return title.noun;
  })();

  const resolvedPageThemes = await getResolvedPageThemeData(site, [
    {
      themeContextName: 'page',
      themes: pageData.theme as Array<PbsKidsTheme>,
      sourceDescription: 'pageData.theme',
    },
    {
      themeContextName: 'page',
      themes: getThemeFromAncestors(pageData.ancestors as Array<PbsKidsPage>),
      sourceDescription: 'getThemeFromAncestors()',
    },
    {
      themeContextName: 'masthead',
      themes: pageData.mastheadTheme as Array<PbsKidsTheme>,
      sourceDescription: 'pageData.mastheadTheme',
      hasMastheadContent,
    },
  ]);

  return {
    props: {
      hasMastheadContent,
      isHomePage,
      pageData,
      pageDescription: description,
      pageProperty,
      pageTitle,
      pageModuleCategory,
      resolvedPageThemes,
    },
  };
}

const firstModuleType = (modules: BodyContentModuleType[]) => {
  return modules?.[0]?.__typename;
};

const firstModuleIsRow = (modules: BodyContentModuleType[]) => {
  if (firstModuleType(modules) === 'BodyContentModulesVideoContentCards' || firstModuleType(modules) === 'BodyContentModulesVideoPlaylist') {
    const firstModule = modules?.[0] as BodyContentModulesVideoContentCards | BodyContentModulesVideoPlaylist;
    return firstModule?.layout === 'row';
  }
};

const renderBodyContentModules = (
  referringPage: string,
  pageModuleCategory: string,
  lazyLoadThreshold: number,
  station: string,
  module: BodyContentModuleType,
  index: number,
) => {
  if (!module) return <></>;

  const identifier = `body-module-${index}-${module.__typename}`;

  if (index < lazyLoadThreshold) {
    // Do not lazy load modules that are likely above the fold.
    // This serves to minimize large layout shifts and boost SEO.
    // See below to see how `lazyLoadThreshold` is calculated.
    return <BodyContentModule
      module={ module }
      referringPage={ referringPage }
      key={ identifier }
    />;
  }

  // Lazy load remaining modules.
  // This maintains a smaller DOM size on page load.
  return (
    <LazyLoader
      identifier={ identifier }
      key={ identifier }
      onLoaded={ (elem) => {
        if (elem) {
          trackModuleViewOnLazyLoad(elem, module as TrackableModulesType, pageModuleCategory, station);
        }
      }}
    >
      <BodyContentModule
        module={ module }
        referringPage={ referringPage }
      />
    </LazyLoader>
  );
};

export default function Page({
  hasMastheadContent,
  isHomePage,
  pageData,
  resolvedPageThemes,
  pageDescription,
  pageTitle,
  pageModuleCategory,
  pageProperty,
}: PageProps) {
  const { station } = useContext(StationLocalizationContext);
  const [ currentThemes, setCurrentThemes ] = useState(resolvedPageThemes);
  const bodyModules = pageData.bodyContentModules as BodyContentModuleType[] || [];
  const mastheadModules = pageData.mastheadContentModules as MastheadContentModuleType[] || [];
  const allModules = mastheadModules.concat(bodyModules);
  const propertiesNavFirst = firstModuleType(bodyModules) === 'BodyContentModulesPropertiesNavigationBanner';
  const modules = propertiesNavFirst ? bodyModules?.slice(1) : bodyModules;

  const hasBackgroundImage = !!(currentThemes.primaryTheme.backgroundImage?.[0]?.url);
  const extraTopPadding = firstModuleIsRow(bodyModules) && hasBackgroundImage && !hasMastheadContent;

  const firstModule = mastheadModules[0] as (
    MastheadContentModulesVideoContentCards | MastheadContentModulesVideoPlaylist
  );
  const hasMastheadCarousel = !!(hasMastheadContent && (firstModule?.__typename === 'MastheadContentModulesVideoPlaylist' || 'MastheadContentModulesVideoContentCards'));

  /**
   * lazyLoadThreshold:
   *
   * the number of body content modules to render server-side.
   * Remaining modules are lazy loaded after page load.
   *
   * If a page contains masthead content and a properties nav as the first module, then only SSR 1 module.
   * If missing masthead content, then SSR an additional module.
   * If the first body module is not a properties nav, then SSR an additional module.
   *
   * Max of 3 body modules are rendered server-side on each page.
   */
  let lazyLoadThreshold = 1;
  if (!hasMastheadContent) lazyLoadThreshold++;
  if (!propertiesNavFirst) lazyLoadThreshold++;

  useSetScrollbarWidthVar();
  /*
    Note: the useGAViewListTracker hook will track modules that are NOT lazy loaded
    as they scroll into view. Lazy loaded modules are tracked separately as they load.
    Also, the hook is invoked twice instead of once with 'allModules' because it will
    only run once to avoid multiple events being sent as the page re-renders, and it
    may run before the module data is fully loaded and concatenated.
  */
  useGAViewListTracker(mastheadModules, pageModuleCategory);
  useGAViewListTracker(bodyModules, pageModuleCategory);
  useGACTAClickTracker(allModules, pageModuleCategory);

  // Station Theme Override
  useEffect(() => {
    setCurrentThemes( setStationThemeOverride(resolvedPageThemes, station.theme?.[0]) );
    setLookInsidePlayTextColor([ document.documentElement ]);
  }, [ station, resolvedPageThemes ]);

  return (<>
    <Head
      pageTitle={ pageTitle }
      pageDescription={ pageDescription }
      pageImageUrl={ pageProperty?.mezzanine?.[0]?.url || pageProperty?.logo?.[0]?.url || '' }
    />
    <ThemeStyles resolvedPageThemes={currentThemes} />

    <MastheadContentWrapper
      hasMastheadContent={ hasMastheadContent }
      hasCarousel={ hasMastheadCarousel }
      isTopLevelPage= {isHomePage }
      pageTitle={ pageTitle }
      propertyId={ pageProperty?.id }
      resolvedPageThemes={ resolvedPageThemes }
    >
      {
        pageData.mastheadContentModules &&
        pageData.mastheadContentModules[0] &&
        <MastheadContentModule
          module={ pageData.mastheadContentModules[0] }
        />
      }
    </MastheadContentWrapper>

    <main>
      {propertiesNavFirst && bodyModules?.[0] &&
      <BodyContentModule
        module={bodyModules?.[0]}
        themeData={resolvedPageThemes}
      />}

      <PageSectionStack
        className={ extraTopPadding ? pageSectionStackStyles.backgroundOffset : ''}
      >
        {modules?.map(
          renderBodyContentModules.bind(null, pageData.id, pageModuleCategory, lazyLoadThreshold, station.callSign),
        )}

        {!!pageProperty?.sponsors?.length && <SponsorsGrid
          sponsors={ pageProperty?.sponsors as Sponsor[] }
          logoStyle={ resolvedPageThemes.primaryTheme.sponsorLogoStyle }
        />}
      </PageSectionStack>
    </main>
  </>);
}
