import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { populateLinkItem, populateVideoItem } from '@/components/modules/MediaList';
import { MediaContext } from '@/types/mediaContext';
import { GamesContentCard, ImageAsset, LinkTargetContent, PbsKidsVideo, VideoContentCard } from '@/types/pbskids-graph';
import { singleTransparentPixel } from '@/utils/theming-system';
import styles from './Carousel.module.scss';
import mediaStyles from '@/components/modules/MediaList/MediaItem.module.scss';

interface SlideProps {
  cardStyle?: string,
  classNames?: string,
  context?: MediaContext,
  inMasthead?: boolean,
  index: number,
  item: ImageAsset | LinkTargetContent | PbsKidsVideo | VideoContentCard | GamesContentCard,
  selected: boolean
  sizes?: string
}

export default function Slide({
  cardStyle,
  classNames,
  context,
  inMasthead,
  index,
  item,
  selected,
  sizes,
}: SlideProps) {
  const itemRef = useRef<HTMLLIElement>(null);
  const [ slideItem, setSlideItem ] = useState<ImageAsset|LinkTargetContent|PbsKidsVideo|null>(null);

  const isAsset = item?.__typename === 'ImageAsset';
  const isVideo = item?.__typename === 'PbsKidsVideo';

  useEffect(() => {
    if (isVideo) {
      setSlideItem(item as PbsKidsVideo);
    } else if (isAsset) {
      setSlideItem(item as ImageAsset);
    } else {
      const castItem = item as VideoContentCard;
      setSlideItem(castItem?.target?.[0] || null);
    }
  }, [ isAsset, isVideo, item ]);

  useEffect(() => {
    const link = itemRef.current?.querySelector('a');

    if (link) {
      const tabIndex = selected ? '0' : '-1';
      link.setAttribute('tabindex', tabIndex);
    }
  });

  if (!slideItem) {
    return null;
  }

  return (
    <li
      ref={itemRef}
      className={`${mediaStyles.mediaItem} ${styles.slide} ${classNames} ${selected ? styles.positionSelected : ''}`}
      data-pbsk-component-carousel-slide={index}
      data-pbsk-component-carousel-slide-active={selected}
    >
      <div className={styles.slideInner}>
        { isVideo &&
          populateVideoItem(item as PbsKidsVideo, true, context, cardStyle, inMasthead)
        }
        { isAsset &&
          <div data-pbsk-component-carousel-slide-asset-wrap>
            <Image
              src={ item?.url || singleTransparentPixel }
              alt=''
              fill={true}
              loading={inMasthead ? 'eager' : 'lazy'}
              priority={ inMasthead }
              sizes={ sizes || '100vw'}
            />
          </div>
        }
        { !isAsset && !isVideo &&
          populateLinkItem(item as VideoContentCard, true, true, cardStyle)
        }
      </div>
    </li>
  );
}
