import { MastheadContentModuleType } from '@/components/modules/MastheadContentModule';
import { PbsKidsVideo, VideoContentCard, GamesContentCard } from '@/types/pbskids-graph';

const hasMediaAsset = (video: PbsKidsVideo) => video?.mediaManagerAsset?.images?.find((image) => image?.profile === 'asset-kids-mezzanine1-16x9');

const videoImageSize = (item: PbsKidsVideo, cardStyle: string | undefined) => {
  switch (cardStyle) {
    case 'smallSquare':
      return item?.squareImage?.[0]?.url;
    case 'largeSquare':
      return item?.squareImage?.[0]?.url;
    case 'poster':
      return item?.posterImage?.[0]?.url;
    case 'mezzanine':
      return item?.mezzanine?.[0]?.url;
    default:
      return;
  }
};

export const filterCards = (cardStyle: string | undefined) => {
  const isMezzanine = cardStyle === 'mezzanine';

  return (card: VideoContentCard | GamesContentCard) => {
    const cardTarget = card?.target?.[0];
    const isVideoLink = cardTarget?.__typename === 'PbsKidsVideo';
    const isGame = cardTarget?.__typename === 'Game';
    const hasCardImage = card?.cardImage?.[0]?.url;

    if (isVideoLink) {
      if (isMezzanine) {
        if (hasMediaAsset(cardTarget)) {
          return card;
        }
      } else {
        if (videoImageSize(cardTarget, cardStyle)) {
          return card;
        }
        return;
      }
    } else if (isGame) {
      if (isMezzanine) {
        if (cardTarget?.mezzanine?.length) {
          return card;
        }
      } else {
        return;
      }
    } else {
      if (hasCardImage) {
        return card;
      }
      return;
    }
  };
};

export const filterVideos = (cardStyle: string | undefined) => {
  const isMezzanine = cardStyle === 'mezzanine';

  return (video: PbsKidsVideo) => {
    if (isMezzanine) {
      if (videoImageSize(video, cardStyle) || hasMediaAsset(video)) {
        return video;
      }
    } else {
      if (videoImageSize(video, cardStyle)) {
        return video;
      }
      return;
    }
  };
};

export const hasValidMastheadContent = (module: MastheadContentModuleType): boolean => {
  if ( module.__typename === 'MastheadContentModulesVideo') {
    if (module.video?.length) {
      return true;
    }
  } else if ( module.__typename === 'MastheadContentModulesFreeformGraphicAndText') {
    if (module.backgroundImage?.[0]?.url && module.smallScreenBackgroundImage?.[0]?.url) {
      return true;
    }
  } else if ( module.__typename === 'MastheadContentModulesLookInsideGamePreview') {
    if (module.game?.length) {
      return true;
    }
  } else if ( module.__typename === 'MastheadContentModulesVideoPlaylist') {
    if (module.collection?.length) {
      return true;
    }
  } else if ( module.__typename === 'MastheadContentModulesVideoContentCards') {
    const cards = module.cards as VideoContentCard[];
    if (cards?.filter(filterCards(module.cardStyle as string))?.length) {
      return true;
    }
  }
  return false;
};
