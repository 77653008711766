import { KeyboardEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import Carousel from '@/components/base/Carousel';
import Pagination from '@/components/base/Pagination';
import { Game, ImageAsset, Maybe } from '@/types/pbskids-graph';
import styles from './MastheadLookInsideGamePreview.module.scss';
import { linkByFeatureFlag } from '../GameCard';

interface Props {
  game?: Maybe<Game>
  listId?: string
}

export default function MastheadLookInsideGamePreview({ game, listId }: Props) {
  const [ carouselImages, setCarouselImages ] = useState<ImageAsset[]>([]);
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const [ updatedSelection, setUpdatedSelection ] = useState(0);

  // Feature flag condition to be removed post release/3.0.0 deploy
  const gameLink = useMemo(() => game ? linkByFeatureFlag(game) : '', [ game ]);

  const goToGame = () => {
    if (gameLink) {
      window.location.href = gameLink;
    }
  };

  const onCarouselClick = (ev: MouseEvent): void => {
    const target = ev.target as HTMLElement;
    const slide = target.closest('[data-pbsk-component-carousel-slide]') as HTMLLIElement;

    if (slide?.dataset?.pbskComponentCarouselSlideActive === 'true') {
      goToGame();
    } else if (slide?.dataset?.pbskComponentCarouselSlide) {
      setUpdatedSelection(parseInt(slide.dataset.pbskComponentCarouselSlide));
    }
  };

  const onKeyUp = (ev: KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter' ) {
      goToGame();
    }
  };

  useEffect(() => {
    const mezzanine = game?.mezzanine as ImageAsset[] || [];
    setCarouselImages(mezzanine.concat(game?.lookInsideAssets as ImageAsset[] || []));
  }, [ game ]);

  if ( !game || !carouselImages?.length || !game.websiteUrl ) {
    return null;
  }

  return (
    <div
      data-pbsk-component-masthead-lookinside-game-preview
      className={styles.moduleWrapper}
    >
      <div className={styles.gameVisuals}>
        <div
          className={styles.carouselBlock}
          onKeyUp={onKeyUp}
          tabIndex={0}
        >
          <Carousel
            autoRotate={true}
            displayNav={false}
            dragToSlide={true}
            inMasthead={true}
            items={carouselImages}
            onCarouselClick={onCarouselClick}
            onSlideSelected={(ev) => setCurrentSlide(ev)}
            preSelectedSlide={updatedSelection}
            sizes='(min-width: 568px) 47vw, (min-width: 1100px) 530px, 75vw'
          />
        </div>

        { carouselImages.length > 1 && (
          <Pagination
            centered={true}
            current={currentSlide}
            slides={carouselImages}
            wrapperStyles={styles.paginationWrapper}
          />
        )}
      </div>
      <div className={styles.gameInfo}>
        { game.gameLogo?.[0]?.url ? (
          <a
            data-ga-cta-click-module={listId}
            data-ga-click-delay
            data-pbsk-component-masthead-lookinside-game-preview-gamelogo
            className={styles.gameLogoWrap}
            href={gameLink}
          >
            <Image
              src={game.gameLogo[0].url}
              alt={game.gameLogo[0].altText || game.title || ''}
              sizes='(min-width: 568px) 28vw, (min-width: 1100px) 300px, 35vw'
              width={game.gameLogo[0]?.width || 230}
              height={game.gameLogo[0]?.height || 230}
              loading='eager'
              priority={true}
            />
          </a>
        ) : (
          <div className={styles.gameTitleFallback}>
            {game.title}
          </div>
        )}

        <a
          aria-label={`Play ${game.title}`}
          data-ga-cta-click-module={listId}
          data-ga-click-delay
          className={styles.playButton}
          href={gameLink}
        >
          Play
        </a>
      </div>
    </div>
  );
}

