import { Game, ImageAsset, Maybe, PbsKidsTheme } from '@/types/pbskids-graph';

import GameCard from '../GameCard';
import GlobalStateContext from '@/components/global/GlobalState';
import Image from 'next/image';
import { useContext, useEffect, useRef, useState } from 'react';
import ThemedModule from '@/components/global/ThemedModule';

import styles from './GamesCharacterFeatureCollage.module.scss';

export enum CharacterFeatureCollageLayouts {
  default = 'default',
  firstEntryFeatured = 'firstEntryFeatured',
}

const applyConstraints = (layout: string, games: Game[]) => {
  // Let's make sure we're not trying to display a number of games that the chosen layout doesn't support.
  // Overall the goal is to honor the layout chosen if possible.
  // The exception to this is that if only one game is provided, we'll force the layout to firstEntryFeatured since that layout is designed for a single game.
  // If the layout is firstEntryFeatured, we'll only display 1 or 3 games.

  let layoutToApply = layout;

  // The CMS shouldn't ever return more than 4, but just in case, let's chop those extras off, since they'll break the layout
  let filteredGames = games.slice(0, 4);

  if (filteredGames.length === 1) {
    // If only one game is provided, force the layout to firstEntryFeatured
    layoutToApply = CharacterFeatureCollageLayouts.firstEntryFeatured;
  } else if (layout === CharacterFeatureCollageLayouts.firstEntryFeatured) {
    // If the layout is firstEntryFeatured, ensure only the supported number of games are displayed: 1 and 3
    if (filteredGames.length >= 3) {
      filteredGames = filteredGames.slice(0, 3);
    } else if (filteredGames.length > 1) {
      filteredGames = filteredGames.slice(0, 1);
    }
  }

  return {
    filteredGames,
    layoutToApply,
  };
};

const getLayoutClasses = (layoutToApply: string, filteredGames: Game[]) => {
  const layoutModeClass =
    layoutToApply === CharacterFeatureCollageLayouts.firstEntryFeatured ?
      styles.layoutModeFirstEntryFeatured :
      styles.layoutModeDefault;

  const itemCountClass = filteredGames.length === 1 ? styles.countOneGame :
    filteredGames.length === 2 ? styles.countTwoGame :
      filteredGames.length === 3 ? styles.countThreeGame :
        filteredGames.length === 4 ? styles.countFourGame : '';

  return `${layoutModeClass} ${itemCountClass}`;
};

export default function GamesCharacterFeatureCollage({
  games,
  layout = CharacterFeatureCollageLayouts.default,
  listId,
  characterImage,
  theme,
}: {
  games: Game[];
  layout?: string;
  listId?: string
  characterImage?: Maybe<Maybe<ImageAsset>[]>;
  theme?: Maybe<PbsKidsTheme> | Maybe<Array<Maybe<PbsKidsTheme>>>
}) {
  const { showThemeDevUI } = useContext(GlobalStateContext);
  const [ showDebugTag, setShowDebugTag ] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement|null>(null);
  const { layoutToApply, filteredGames } = applyConstraints(layout, games);

  useEffect(() => {
    wrapperRef?.current?.style?.setProperty(
      '--pbsk-component-char-feat-collage-game-count', filteredGames.length.toString(),
    );

    if (showDebugTag !== showThemeDevUI) {
      setShowDebugTag(showThemeDevUI);
    }
  }, [ filteredGames.length, showDebugTag, showThemeDevUI ]);

  // Require a theme, per Ivy's request
  if (
    (!Array.isArray(theme) && !theme) ||
    (Array.isArray(theme) && !(((theme as Array<PbsKidsTheme>)?.length || 0) > 0))
  ) {
    return <></>;
  }

  // Require at least one game
  if (filteredGames.length < 1) {
    return <></>;
  }

  // Require a valid character image
  if (!characterImage?.[0]?.url || !characterImage?.[0]?.width || !characterImage?.[0]?.height) {
    return <></>;
  }

  const imageSizes = '(min-width: 1024px) 225px, (min-width: 768px) 300px, 360px';
  const featuredGameImageSizes = '(min-width: 1024px) 487px, (min-width: 768px) 630px, 710px';

  const layoutClassNames = getLayoutClasses(layoutToApply, filteredGames);

  const DebugTag = () => <pre className={styles.debugTag}>
    {JSON.stringify(
      {
        layoutRequested: layout,
        layoutApplied: layoutToApply,
        inputGamesCount: games.length,
        filteredGameCount: filteredGames.length,
      },
      null, 2,
    )}
  </pre>;

  return (
    <>
      <ThemedModule
        theme={theme}
        backgroundMode='image'
        contentClassName={styles.themedContentWrapper}
      >
        {showDebugTag && <DebugTag />}

        <div ref={wrapperRef} className={`${styles.wrapper} ${layoutClassNames}`}>
          <ul
            data-ga-view-list-module={listId}
            className={styles.theGrid}
          >
            {filteredGames.map((game: Game, index: number) => (
              <li
                key={ index }
                className={styles.gamesListItem}
              >
                <GameCard
                  enableLookInside={ false }
                  key={ index }
                  gameItem={ game }
                  sizes={
                    layoutToApply === CharacterFeatureCollageLayouts.firstEntryFeatured && index === 0 ?
                      featuredGameImageSizes :
                      imageSizes
                  }
                ></GameCard>
              </li>
            ))}
          </ul>
          <div className={styles.characterImage}>
            <Image
              src={characterImage?.[0].url}
              width={characterImage?.[0].width}
              height={characterImage?.[0].height}
              alt=''
            />
          </div>
        </div>
      </ThemedModule>
    </>
  );
}
