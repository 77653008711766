import { CharacterFeatureCollageLayouts } from '@/components/modules/GamesCharacterFeatureCollage';
import { clearGAEcommerceObj, sendEventToGA } from '@/managers/Analytics';
import { prepareEpisodeVideoType } from '@/managers/Analytics/videoEvents';
import {
  BodyContentModulesFreeformGraphicAndText,
  BodyContentModulesGamesCharacterFeatureCollage,
  BodyContentModulesGamesCollage,
  BodyContentModulesGamesContentCards,
  BodyContentModulesGameSet,
  BodyContentModulesLiveTv,
  BodyContentModulesPropertiesNavigationBanner,
  BodyContentModulesVideo,
  BodyContentModulesVideoContentCards,
  BodyContentModulesVideoPlaylist,
  Game,
  GamesContentCard,
  MastheadContentModulesFreeformGraphicAndText,
  MastheadContentModulesLookInsideGamePreview,
  MastheadContentModulesVideo,
  MastheadContentModulesVideoPlaylist,
  PbsKidsGamesWebPage,
  PbsKidsProperty,
  PbsKidsVideo,
  PbsKidsVideoPlaylist,
  PbsKidsVideosByPropertyCollection,
  PbsKidsVideosWebPage,
  PropertiesNavBannerCard,
  VideoContentCard,
} from '@/types/pbskids-graph';
import hasProperty from '@/utils/has-property';

export type TrackableModulesType =
BodyContentModulesFreeformGraphicAndText |
BodyContentModulesGamesCharacterFeatureCollage |
BodyContentModulesGamesCollage |
BodyContentModulesGamesContentCards |
BodyContentModulesGameSet |
BodyContentModulesLiveTv |
BodyContentModulesPropertiesNavigationBanner |
BodyContentModulesVideo |
BodyContentModulesVideoContentCards |
BodyContentModulesVideoPlaylist |
MastheadContentModulesFreeformGraphicAndText |
MastheadContentModulesLookInsideGamePreview |
MastheadContentModulesVideo |
MastheadContentModulesVideoPlaylist |
PbsKidsVideoPlaylist |
PbsKidsVideosByPropertyCollection;

type TrackableModuleItemType =
Game |
GamesContentCard |
PbsKidsGamesWebPage |
PbsKidsVideo |
PbsKidsVideosByPropertyCollection |
PbsKidsVideoPlaylist |
PbsKidsVideosWebPage |
PropertiesNavBannerCard |
VideoContentCard;

const noDataStr = 'no-data';

const checkNavPropBgImage = (moduleElem: Element) => {
  return moduleElem.hasAttribute('data-ga-has-thumb-bg');
};

const getModuleType = (moduleData: TrackableModulesType|TrackableModuleItemType): string => {
  /*
    Possible module types for analytics:
      carousel,
      default-games-character-collage,
      featured-games-character-collage,
      freeform,
      games-collage,
      grid,
      live-tv-module,
      look-inside-game-preview,
      property-navigation,
      row,
      single-video,
      stacked-row.
    To be prefixed with 'header-' or 'body-' depending on their location.
  */

  let type;
  const prefix = () => moduleData.__typename?.includes('Masthead') ? 'header-' : 'body-';

  if (hasProperty(moduleData, 'layout')) {
    const layout = moduleData.layout === 'stackedRow' ? 'stacked-row' : moduleData.layout;
    type = prefix() + layout;
  }

  // Modules that don't have a layout property or that need to be manually mapped.
  switch (moduleData.__typename) {
    case 'BodyContentModulesGamesCollage':
      type = 'body-games-collage';
      break;
    case 'BodyContentModulesGamesCharacterFeatureCollage':
      type = prefix() + (moduleData.layout === CharacterFeatureCollageLayouts.default ? 'default-' : 'featured-') + 'games-character-collage';
      break;
    case 'BodyContentModulesFreeformGraphicAndText':
    case 'MastheadContentModulesFreeformGraphicAndText':
      type = prefix() + 'freeform';
      break;
    case 'BodyContentModulesLiveTv':
      type = 'body-live-tv';
      break;
    case 'BodyContentModulesPropertiesNavigationBanner':
      type = 'body-property-navigation';
      break;
    case 'BodyContentModulesVideo':
    case 'MastheadContentModulesVideo':
      type = prefix() + 'single-video';
      break;
    case 'MastheadContentModulesLookInsideGamePreview':
      type = 'masthead-look-inside-game-preview';
      break;
  }

  return type || `${prefix()}grid`;
};

const getOrderFromIndex = (ind: number): string => {
  // Order is a 2 char string and starts at 00.
  return ind.toString().padStart(2, '0');
};

const getModulePosition = (el: Element): string => {
  const ind = Array.from(document.querySelectorAll('[data-ga-view-list-module], [data-ga-cta-click-module]')).indexOf(el);
  return typeof ind === 'number' && ind > -1 ? getOrderFromIndex(ind) : 'position not found';
};

const getItemTypeLabel = (itemTypename: string): string => {
  // could be a page, playlist, show, or video.
  // game and podcast to come later.
  const targetMap: { [key: string]: string } = {
    Game: 'game',
    PbsKidsGamesWebPage: 'page',
    PbsKidsMultiPlatformSeries: 'show',
    PbsKidsVideosWebPage: 'page',
    PbsKidsVideo: 'video',
    PbsKidsVideoPlaylist: 'playlist',
    PbsKidsVideosByPropertyCollection: 'playlist',
  };

  return itemTypename ? targetMap[itemTypename] : '';
};

const getItemShowLabel = (item: TrackableModuleItemType|TrackableModulesType): string => {
  if (!item) return '';

  if (hasProperty(item, 'property')) {
    const isArr = item.property instanceof Array;
    const property = isArr ?
      (item.property as PbsKidsProperty[])[0] :
      item.property as PbsKidsProperty;

    return property?.title || 'no-property';
  }

  if (hasProperty(item, 'properties')) {
    const properties = item.properties as PbsKidsProperty[];
    return properties && properties.length > 1 ? 'multiple-properties' : (properties?.[0]?.title || 'no-property');
  }

  if (hasProperty(item, 'shows')) {
    const properties = item.shows as PbsKidsProperty[];
    return properties && properties.length > 1 ? 'multiple-properties' : (properties?.[0]?.title || 'no-property');
  }

  return 'no-property';
};

const getFormattedListItem = (
  item: TrackableModuleItemType,
  index: number,
  moduleName: string,
  moduleOrder: string,
  moduleType: string,
  thumbStyle: string,
): Record<string, string|number> => {
  const result = <Record<string, string|number>>{
    index: getOrderFromIndex(index),
    item_id: item?.id || '',
    item_module_name: moduleName,
    item_module_position: moduleOrder,
    item_module_type: moduleType,
    item_thumbnail_id: thumbStyle,
  };

  switch (item?.__typename) {
    case 'Game':
      result.item_guid = item.guid || noDataStr;
      result.item_media_type = getItemTypeLabel(item.__typename) || noDataStr;
      result.item_name = item.title || noDataStr;
      result.item_show_name = getItemShowLabel(item);
      break;

    case 'PbsKidsVideo':
      result.item_guid = item.guid || noDataStr;
      result.item_media_type = getItemTypeLabel(item.__typename) || noDataStr;
      result.item_name = item.title || noDataStr;
      result.item_show_name = getItemShowLabel(item);
      result.item_video_tp_media = item?.mediaManagerAsset?.legacy_tp_media_id || noDataStr;
      result.item_video_type = prepareEpisodeVideoType(item.videoType || noDataStr, 'full_length');
      break;

    case 'PbsKidsVideoPlaylist':
      result.item_media_type = getItemTypeLabel(item.__typename) || noDataStr;
      result.item_name = item.title || noDataStr;
      result.item_show_name = getItemShowLabel(item);
      break;

    case 'PropertiesNavBannerCard': {
      const title = item.property?.[0]?.title || noDataStr;
      result.item_media_type = 'show';
      result.item_show_name = title;
      result.item_name = title;
      break;
    }

    case 'GamesContentCard':
    case 'VideoContentCard': {
      const itemTarget = item.target?.[0];

      if (itemTarget && hasProperty(itemTarget, 'guid')) {
        result.item_guid = itemTarget?.guid as string|undefined || noDataStr;
      }

      result.item_id = itemTarget?.id || noDataStr;
      result.item_name = itemTarget?.title || noDataStr;
      result.item_media_type = getItemTypeLabel(itemTarget?.__typename || '') || noDataStr;
      result.item_show_name = getItemShowLabel(itemTarget as TrackableModuleItemType);
      break;
    }
  }

  return result;
};

const getFormattedListItems = (
  moduleName: string,
  moduleOrder: string,
  moduleType: string,
  thumbStyle: string = '',
  items: Array<TrackableModuleItemType>,
  indexOverride: number = -1,
): Array<object> => {
  return items.map((item, index) => {
    const itemIndex = indexOverride > -1 ? indexOverride : index;
    return getFormattedListItem(item, itemIndex, moduleName, moduleOrder, moduleType, thumbStyle);
  });
};

const getCTAModuleParams = (
  eventName: string,
  elem: Element,
  moduleData: TrackableModulesType,
  modulePosition: string,
  moduleCategory: string,
) => {
  const anchor = hasProperty(moduleData, 'anchor') ? moduleData.anchor : '';
  const moduleName = anchor || `${moduleData.id}_${moduleData.__typename}`;
  let clickText = '';
  let clickUrl = '';
  let showTitle = '';

  switch (moduleData.__typename) {
    case 'BodyContentModulesFreeformGraphicAndText':
    case 'MastheadContentModulesFreeformGraphicAndText': {
      const target = hasProperty(moduleData, 'target') ? moduleData.target as TrackableModuleItemType[] : [];
      if (target.length) {
        clickText = hasProperty(target[0], 'title') ? target[0].title as string : noDataStr;
        clickUrl = hasProperty(target[0], 'url') ? target[0].url as string : noDataStr;
        showTitle = getItemShowLabel(target[0]);
      }
      break;
    }
    case 'BodyContentModulesLiveTv': {
      const linkEl = elem.closest(`[data-ga-cta-click-module="${moduleData.id}"]`)?.querySelector('a');
      clickText = linkEl?.querySelector('p')?.textContent || noDataStr;
      clickUrl = linkEl?.getAttribute('href') || noDataStr;
      showTitle = getItemShowLabel(moduleData);
      break;
    }
    case 'MastheadContentModulesLookInsideGamePreview': {
      const game = moduleData.game?.[0];
      clickUrl = game?.websiteUrl || noDataStr;
      showTitle = game ? getItemShowLabel(game) : noDataStr;
      // This module has two trackable elements inside it.
      if (elem.hasAttribute('data-pbsk-component-masthead-lookinside-game-preview-gamelogo')) {
        clickText = game?.title || noDataStr;
      } else if (elem.nodeName === 'A') {
        clickText = elem.textContent || noDataStr;
      }
      break;
    }
  }

  const eventParams = {
    'event': eventName,
    'click_text': clickText,
    'click_url': clickUrl,
    'module_category': moduleCategory,
    'module_name': moduleName,
    'module_position': modulePosition,
    'module_type': getModuleType(moduleData),
    'show_title': showTitle,
  };

  return eventParams;
};

const getGAListModuleParams = (
  eventName: string,
  moduleData: TrackableModulesType,
  modulePosition: string,
  moduleCategory: string,
  station: string,
  hasBgThumb: boolean,
  singleItemIndex: number = -1,
): Record<string, unknown> => {
  let items: Array<TrackableModuleItemType> = [];
  let moduleName;
  let thumbStyle;

  switch (moduleData.__typename) {
    case 'BodyContentModulesGamesCharacterFeatureCollage': {
      items = moduleData.games as Array<Game>;
      moduleName = moduleData.anchor;
      thumbStyle = 'mezzanine';
      break;
    }
    case 'BodyContentModulesGameSet': {
      const game = moduleData.collection?.[0]?.entries?.[singleItemIndex] as Game;
      items = moduleData.collection?.[0]?.entries as Array<Game>;
      moduleName = moduleData.anchor;
      thumbStyle = game?.lookInsideAssets?.length ? 'look-inside' : 'mezzanine';
      break;
    }
    case 'BodyContentModulesGamesCollage': {
      const game = moduleData.games?.[singleItemIndex] as Game;
      items = moduleData.games as Array<Game>;
      moduleName = moduleData.anchor;
      thumbStyle = moduleData.enableLookInside && game?.lookInsideAssets?.length ? 'look-inside' : 'mezzanine';
      break;
    }
    case 'BodyContentModulesGamesContentCards':
      items = moduleData.cards as Array<GamesContentCard>;
      moduleName = moduleData.anchor;
      thumbStyle = 'mezzanine';
      break;
    case 'BodyContentModulesVideo':
    case 'MastheadContentModulesVideo':
      items = moduleData.video as Array<PbsKidsVideo>;
      moduleName = moduleData.anchor;
      thumbStyle = 'singleVideo';
      break;
    case 'BodyContentModulesVideoPlaylist':
    case 'MastheadContentModulesVideoPlaylist':
      items = moduleData.collection?.[0]?.entries as Array<PbsKidsVideo>;
      moduleName = moduleData.anchor;
      thumbStyle = moduleData.cardStyle || '';
      break;
    case 'BodyContentModulesVideoContentCards':
      items = moduleData.cards as Array<VideoContentCard>;
      moduleName = moduleData.anchor;
      thumbStyle = moduleData.cardStyle || '';
      break;
    case 'BodyContentModulesPropertiesNavigationBanner':
      items = moduleData.cards as Array<PropertiesNavBannerCard>;
      moduleName = moduleData.anchor;
      thumbStyle = hasBgThumb ? 'background' : 'no-background';
      break;
    case 'PbsKidsVideoPlaylist':
    case 'PbsKidsVideosByPropertyCollection':
      items = moduleData.entries as Array<PbsKidsVideo>;
      moduleName = moduleData.title;
      thumbStyle = 'mezzanine';
      break;
  }

  if (!moduleName) {
    moduleName = `${moduleData.id}_${moduleData.__typename}`;
  }

  if (singleItemIndex > -1) {
    // Only tracking a single list item.
    const singleItem = items[singleItemIndex];
    items = [ singleItem ];
  }

  const moduleType = getModuleType(moduleData);

  const eventParams = {
    'event': eventName,
    'module_category': moduleCategory,
    'module_name': moduleName,
    'module_type': moduleType,
    'module_position': modulePosition,
    'station_localization': station,
    'ecommerce': {
      'item_list_id': moduleName,
      'items': getFormattedListItems(moduleName, modulePosition, moduleType, thumbStyle, items, singleItemIndex) || [],
    },
  };

  return eventParams;
};

const matchElemToPageModuleData = (elem: Element, modules: TrackableModulesType[]) => {
  return modules?.find((module) => (module?.id ?? -1) === elem.getAttribute('data-ga-view-list-module')) ||
  modules?.find((module) => (module?.id ?? -1) === elem.getAttribute('data-ga-cta-click-module'));
};

const sendModuleCTAClickEvent = (
  elem: Element,
  module: TrackableModulesType,
  moduleCategory: string,
  modulePosition: string,
) => {
  const gaEventParams = getCTAModuleParams('cta_click', elem, module, modulePosition, moduleCategory);
  sendEventToGA(gaEventParams);
};

const sendModuleSelectItemEvent = (
  itemIndex: number,
  module: TrackableModulesType,
  modulePosition: string,
  moduleCategory: string,
  station: string,
  hasBgThumb: boolean,
) => {
  clearGAEcommerceObj();
  const gaEventParams = getGAListModuleParams('select_item', module, modulePosition, moduleCategory, station, hasBgThumb, itemIndex);
  sendEventToGA(gaEventParams);
};

const sendModuleViewEvent = (
  module: TrackableModulesType,
  modulePosition: string,
  moduleCategory: string,
  station: string,
  hasBgThumb: boolean,
) => {
  clearGAEcommerceObj();
  const gaEventParams = getGAListModuleParams('view_item_list', module, modulePosition, moduleCategory, station, hasBgThumb);
  sendEventToGA(gaEventParams);
};

const trackModuleCTAClick = (elem: Element, moduleItem: TrackableModulesType, moduleCategory: string): void => {
  const parentEl = elem.closest('[data-ga-cta-click-module]');

  if (!parentEl) return;

  const modulePosition = getModulePosition(parentEl);
  sendModuleCTAClickEvent(elem, moduleItem, moduleCategory, modulePosition);
};

const trackModuleListItemClick = (elem: Element, moduleData: TrackableModulesType, moduleCategory: string, station: string = ''): void => {
  const listItem = elem.closest('li');

  if (!listItem) return;

  const listEl = listItem.closest('ul[data-ga-view-list-module]');

  if (!listEl) return;

  const modulePosition = getModulePosition(listEl);
  const listElChildren = listEl.querySelectorAll('li');
  const clickedItemIndex = Array.from(listElChildren || [])?.indexOf(listItem);
  const hasThumbBg = checkNavPropBgImage(listEl);

  if (clickedItemIndex > -1 && moduleData) {
    sendModuleSelectItemEvent(clickedItemIndex, moduleData, modulePosition, moduleCategory, station, hasThumbBg);
  }
};

const trackModuleView = (
  elem: Element,
  moduleData: TrackableModulesType,
  moduleCategory: string,
  station: string,
): void => {
  if (!moduleData) return;

  const hasThumbBg = checkNavPropBgImage(elem);
  const modulePosition = getModulePosition(elem);

  if (modulePosition !== 'position not found') {
    sendModuleViewEvent(moduleData, modulePosition, moduleCategory, station, hasThumbBg);
  }
};

const trackModuleViewOnLazyLoad = (
  elem: Element, moduleData: TrackableModulesType, moduleCategory: string, station: string, delay: number = 500,
): void => {
  window.setTimeout(() => {
    const target = elem?.hasAttribute('data-ga-track-next-sibling') ? elem.nextElementSibling : elem;
    const trackedModule = target?.querySelector('[data-ga-view-list-module]');

    if (trackedModule) {
      trackModuleView(trackedModule, moduleData, moduleCategory, station);
    }
  }, delay);
};

const delayLinkClickEvent = (ev: Event, link: Element): void => {
  // For links that send a user to an external site,
  // add a delay so the GA event has time to be sent.
  // We can prob remove when the game player is on our own site.
  ev.preventDefault();
  const href = link?.getAttribute('href');

  if (!href) return;

  link?.setAttribute('disabled', 'true');

  window.setTimeout(() => {
    window.location.href = href;
    link?.removeAttribute('disabled');
  }, 500);
};

const handleTrackedModuleItemClick = (
  moduleData: TrackableModulesType,
  moduleCategory: string,
  station: string,
  inList: boolean,
  ev: Event,
) => {
  const elem = ev.target as Element;
  const link = elem.nodeName === 'A' ? elem : elem.closest('a');

  if (link && link.hasAttribute('disabled')) {
    ev.preventDefault();
    return;
  }

  if (elem.hasAttribute('data-ga-click-delay')) {
    link && delayLinkClickEvent(ev, link);
  }

  if (inList) {
    trackModuleListItemClick(elem, moduleData as TrackableModulesType, moduleCategory, station);
  } else {
    trackModuleCTAClick(elem, moduleData, moduleCategory);
  }
};

export {
  handleTrackedModuleItemClick,
  matchElemToPageModuleData,
  trackModuleView,
  trackModuleViewOnLazyLoad,
};
